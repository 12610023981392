import * as React from 'react';
import ItemsSelector from 'components/items_selector/items_selector';
import Form from 'components/form';
import { ComponentFormProps, SubscriptionState } from './types';

declare global {
  interface Window {
    analyticsClient: any; // this is needed so TS doesn't whine about analyticsClient
  }
}

export default class ComponentForm extends React.Component<
  ComponentFormProps,
  SubscriptionState
> {
  state = {
    globalToggleName: 'Select none',
    blockSave: false,
    initialSubscribeShow: false,
  };

  handleToggle(items: any) {
    this.updateGlobalToggleName(items);
    this.validateSelection(items);
  }

  // No checkboxes selected --> "Select all"
  // Otherwise --> "Select none"
  updateGlobalToggleName(items: any) {
    let newName = 'Select none';

    // Look for any uncheck checked box. If we find one, change to "Select all".
    items.forEach((item: any) => {
      if (item.group) {
        item.children.forEach((child: any) => {
          if (!child.selected) {
            newName = 'Select all';
            return; // stop iterating
          }
        });
      } else {
        if (!item.selected) {
          newName = 'Select all';
          return; // stop iterating
        }
      }
    });

    this.setState({ globalToggleName: newName });
  }

  // validation for certain states
  validateSelection(items: any) {
    // edit form: never block save
    if (this.props.allowUnsubscribe) {
      this.setState({ blockSave: false });
      return;
    }

    // new form: block save if no components are selected
    let noComponents = true;
    items.forEach((item: any) => {
      if (item.group) {
        item.children.forEach((child: any) => {
          if (child.selected) {
            noComponents = false;
            return;
          }
        });
      } else {
        if (item.selected) {
          noComponents = false;
          return;
        }
      }
    });

    this.setState({ blockSave: noComponents });
  }

  render() {
    if (!this.props.allowComponentSubscribers) {
      return null;
    }

    const saveClassName = `flat-button${
      this.state.blockSave ? ' disabled' : ''
    }`;

    return (
      <Form
        action={this.props.formAction}
        method={this.props.formMethod}
        className="component-form"
      >
        <div>
          <h6>
            Components{' '}
            <a
              className="global-toggle-btn"
              id="component-selector-toggle"
              tabIndex={0}
            >
              {this.state.globalToggleName}
            </a>
          </h6>
        </div>

        <ItemsSelector
          className="inline"
          items={this.props.components}
          preselectedItemIds={this.props.selectedComponentIds}
          showHeader={false}
          showFooter={false}
          hiddenFieldName="subscriber[component_ids]"
          toggleAllclickTargetId="component-selector-toggle"
          onToggle={this.handleToggle.bind(this)}
          showTooltipDescriptions={true}
        />

        <input type="hidden" name="id" value={this.props.subscriber.id} />

        <div className="form-actions">
          <a href="/" className="cancel-btn">
            Cancel
          </a>
          <button
            disabled={this.state.blockSave}
            className={saveClassName}
            type="submit"
          >
            Save
          </button>
        </div>
      </Form>
    );
  }
}
